<template>
  <div class="login">
    <img class="background" src="./images/img_bg@2x.png" />
    <div class="background_title">
      <div class="first">济南繁星跳动科技有限公司版权所有</div>
      <a class="first active" target="blank" style="margin-top: 5px;text-decoration: none;"
        href="https://beian.miit.gov.cn">网站ICP备案号:鲁ICP备2024098206号 </a>
      <div class="last">
        <div>Copyright</div>
        <div>©</div>
        <div>2019-2021</div>
        <div>繁星跳动科技</div>
        <div>All</div>
        <div>Right</div>
        <div>Reserved</div>
      </div>
    </div>
    <div class="login-page">
      <div class="header">
        <img src="https://img.fanxinghuishou.cn/20230814/185432266.png" alt />
        <span>济南繁星回收后台管理系统—平台端</span>
      </div>

      <div class="main">
        <div class="content">
          <div class="title">Welcome Back！</div>
          <div class="title2">登录</div>
          <input class="input" type="text" v-model="params.username" placeholder="请输入登录账号" />
          <input type="text" style="display: none" />
          <div class="show_pass">
            <input class="input" type="text" placeholder="请输入登录密码" v-if="pwdType" v-model="params.pwd">
            <input class="input" type="password" placeholder="请输入登录密码" v-model="params.pwd" v-else>
            <div class="show_img">
              <img :src="seen ? seenImg : unseenImg" @click="changeType()" class="eye_img" />
            </div>
          </div>
          <el-checkbox class="remember-code" v-model="checkedLogin">记住密码</el-checkbox>
          <div class="submit" @click="handleLodin">登录</div>
        </div>
      </div>

      <div class="footer">RECYCLE</div>
    </div>
    <!-- 验证弹框 -->
    <el-dialog class="acc_dialog" title="登录验证" :visible.sync="commandShow" :close-on-click-modal="false" width="500px">
      <div style="
          font-size: 16px;
          color: #666;
          height: 100px;
          line-height: 50px;
        ">
        <div style=" margin-bottom: 20rpx; text-align: center">
          登录前请先进行安全验证
        </div>
        <div class="command">
          <div>动态口令：</div>
          <el-input v-model="commandTex" placeholder="请输入动态口令验证"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow=false">取消</el-button>
        <el-button type="primary" @click.native="sumitLogin">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "login",
  data() {
    return {
      // 登录验证
      commandShow:false,
      commandTex:'',
      // *******************
      seen: '',
      unseenImg: require('@/assets/images/login_icon_eye_close@2x.png'),//看不见
      seenImg: require('@/assets/images/login_icon_eye_@2x.png'),//看得见密码
      isLoading: false,
      pwdType: false,
      checkedLogin: false,
      params: {
        username: '',
        pwd: ''
      }
    };
  },
  created() {
    if (JSON.parse(localStorage.getItem('loginUser'))) {
      this.params.username = JSON.parse(localStorage.getItem('loginUser')).username
      this.params.pwd = JSON.parse(localStorage.getItem('loginUser')).pwd
      this.checkedLogin = JSON.parse(localStorage.getItem('loginUser')).checkedLogin
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown);
  },
  methods: {
    //密码的显示隐藏
    changeType: function () {
      this.seen = !this.seen;//小眼睛的变化
      this.pwdType = !this.pwdType;//跟着小眼睛变化，密码框隐藏显示文本框，内容就显示了
    },
    handleLodin(){
      this.commandShow=true
    },
    // 原：handleLodin-->sumitLogin
    sumitLogin() {
      const pcLoginRequest = {
        pageNum: 0,
        pageSize: 0,
        userName: this.params.username,
        password: this.params.pwd,
        token: "",
        command:this.commandTex
      };
      if (this.params.username !== "" && this.params.pwd !== "") {
        // this.pcLoginRequest = JSON.stringify(pcLoginRequest)
        _api.postLogin(pcLoginRequest).then(res => {
          //请求返回来的数据
          console.log(res);
          //  保存token：
          if (res.code === 1) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userImg", res.data.user.userImg);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("roleId", res.data.user.roleId);
            localStorage.setItem("userId", res.data.user.userId);
            if (this.checkedLogin) {
              let userForm = this.params
              console.log(userForm);
              userForm.checkedLogin = this.checkedLogin
              localStorage.setItem('loginUser', JSON.stringify(userForm))
            } else {
              localStorage.removeItem('loginUser')
            }
            this.$router.push({ path: "/dashboard" });
            this.$message({
              showClose: true,
              message: "登录成功",
              type: "success"
            });
          } else {
            //  登录失败
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error"
            });
          }
        });
        // } else {
        //   this.$message({
        //     showClose: true,
        //     message: "用户名和密码不能为空",
        //     type: "error"
        //   });
      }
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        // this.sumitLogin();
        this.commandShow=true
      }
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false);
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.command {
  display: flex;
  align-items: center;
}
.command .el-input {
  width: 80%;
}
.login {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  position: relative;
  min-height: 700px;

  .background_title {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    text-align: right;
    z-index: 999;

    .first {
      color: #ffffff;
      width: 365px;
      font-size: 14px;
    }

    .first.active {
      cursor: pointer;
    }

    .first.active:hover {
      text-decoration: underline !important;
    }

    .last {
      margin: 5px;
      display: flex;
      height: 15px;
      font-size: 14px;
      width: 365px;
      justify-content: space-around;
      align-items: center;
    }
  }

  .background {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    object-fit: contain;
    z-index: 0;
  }

  .login-page {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    .header {
      position: absolute;
      left: 50px;
      top: 29px;
      display: flex;
      align-items: center;

      >img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 13px;
      }

      >span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #0981ff;
      }
    }

    .main {
      position: absolute;
      left: 200px;
      top: 156px;
      width: 560px;
      height: 620px;
      background: #0981ff;
      box-shadow: 0px 5px 96px 0px rgba(78, 130, 185, 0.1),
        0px 30px 70px 0px rgba(78, 130, 185, 0.27);
      border-radius: 20px;
      padding: 8px 0 0 0;

      .content {
        width: 560px;
        height: 612px;
        background: #ffffff;
        border-radius: 20px;
        padding: 50px 50px 0px 50px;

        .title {
          font-size: 46px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-bottom: 40px;
        }

        .title2 {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .input {
          display: block;
          width: 100%;
          border: none;
          border-bottom: 1px solid #e7eaee;
          padding: 40px 0;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          outline: none;

          &::placeholder {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #b6b9d0;
            outline: none;
          }
        }

        .remember-code {
          margin: 20px 0 53px 0;
        }

        .submit {
          width: 460px;
          height: 60px;
          background: #0981ff;
          border-radius: 10px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .footer {
      position: absolute;
      left: 52px;
      bottom: 39px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0981ff;
    }
  }

  .show_pass {
    position: relative;

    .show_img {
      position: absolute;
      top: 40%;
      right: 5px;
      width: 25px;
      height: 25px;
    }
  }
}
</style>
